import {
    REQUEST_EXPENSES,
    RECEIVE_EXPENSES,
    UPDATE_EXPENSES,
    ERROR_EXPENSES,
    RELOAD_EXPENSES
} from '../types'
import {fetchUrl} from '../../constants/constants'
import {
    requestItem,
    receiveItem,
    failedItem,
    reloadDashboard,
    reloadBankTransactions,
    updateItem,
    reloadEnums
} from "../actions"
import fetchErrorCtrl from "../../helpers/fetchErrorCtrl"
import shouldFetch from "../../helpers/shouldFetch"
import axios from 'axios'
import prepareFilters from "../../helpers/prepareFilters"

function request() {
    return {
        type: REQUEST_EXPENSES
    };
}

export function receive(data) {
    return {
        type: RECEIVE_EXPENSES,
        payload: data
    }
}

function failed(data) {
    return {
        type: ERROR_EXPENSES,
        errors: data
    }
}

function update(data, id, updateType) {
    return {
        type: UPDATE_EXPENSES,
        id,
        payload: data,
        updateType
    }
}

export function reloadExpenses(){
    return{
        type: RELOAD_EXPENSES
    }
}

export function getExpenses(params = {}, force = false) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().expenses, !force)) {
            dispatch(request())
            return axios({
                method: 'GET',
                url: fetchUrl + 'expenses',
                params: prepareFilters(params, getState(), "expenses")
            }).then(res => {
                dispatch(receive(res.data.data))
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failed(errors))
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function getExpense(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('expense'))
            return axios({
                method: 'GET',
                url: fetchUrl + 'expenses/' + id
            }).then(res => {
                dispatch(receiveItem(res.data.data))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function getExpenseWarehouse(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('expense'))
            return axios({
                method: 'GET',
                url: fetchUrl + 'expenses/' + id + '/warehouse'
            }).then(res => {
                dispatch(updateItem(res.data.data, "warehouse_items"))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function updateExpenseWarehouse(data, id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('expense'))
            return axios({
                method: 'PATCH',
                url: fetchUrl + 'expenses/' + id + '/warehouse',
                data
            }).then(res => {
                dispatch(updateItem(res.data.data, "warehouse_items"))
                dispatch(reloadEnums("warehouse"))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function createExpense(data) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem())
            return axios({
                method: 'POST',
                url: fetchUrl + 'expenses',
                data
            }).then(res => {
                dispatch(receiveItem({}, 'message.item_created'))
                dispatch(update(res.data.data, false, 'create'))
                dispatch(reloadDashboard())
                dispatch(reloadBankTransactions())
                dispatch(reloadEnums("expenses.proforma"))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function updateExpense(data, id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('expense'))
            return axios({
                method: 'PUT',
                url: fetchUrl + 'expenses/' + id,
                data
            }).then(res => {
                dispatch(receiveItem(res.data.data, 'message.item_updated'))
                dispatch(update(res.data.data, id, 'update'))
                dispatch(reloadDashboard())
                dispatch(reloadBankTransactions())
                dispatch(reloadEnums("expenses.proforma"))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function toggleAccountedExpense(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('expense'))
            return axios({
                method: 'PATCH',
                url: fetchUrl + 'expenses/set-accounted/' + id
            }).then(res => {
                dispatch(receiveItem(res.data.data, 'message.item_updated'))
                dispatch(update(res.data.data, id, 'update'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function togglePrintedExpense(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('expense'))
            return axios({
                method: 'PATCH',
                url: fetchUrl + 'expenses/set-printed/' + id
            }).then(res => {
                dispatch(receiveItem(res.data.data, 'message.item_updated'))
                dispatch(update(res.data.data, id, 'update'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function toggleVATDeductibleExpense(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem('expense'))
            return axios({
                method: 'PATCH',
                url: fetchUrl + 'expenses/set-vat-deductible/' + id
            }).then(res => {
                dispatch(receiveItem(res.data.data, 'message.item_updated'))
                dispatch(update(res.data.data, id, 'update'))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}

export function deleteExpense(id) {
    return (dispatch, getState) => {
        if (shouldFetch(getState().item)) {
            dispatch(requestItem())
            return axios({
                method: 'DELETE',
                url: fetchUrl + 'expenses/' + id
            }).then(res => {
                dispatch(receiveItem({}, 'message.item_deleted'))
                dispatch(update(res.data.data, id, 'delete'))
                dispatch(reloadDashboard())
                dispatch(reloadEnums("expenses.proforma"))
                return true
            }).catch(err => {
                const errors = fetchErrorCtrl(err)
                dispatch(failedItem(errors))
                return errors
            })
        } else {
            return Promise.resolve()
        }
    }
}